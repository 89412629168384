$('.house-widget').on('mouseenter', function() {
    $('.house-widget').removeClass('active');
    $(this).addClass('active');
})

$('.house-widget').on('click', function() {
    $('.house-widget').removeClass('active');

    let n = $(this).data('n');

    $('#flat' + n).css('background-color', '#bdbdbd');

    $([document.documentElement, document.body]).animate({
        scrollTop: $('#flat' + n).offset().top - 150
    }, 1000);

    setTimeout(() => {
        $(document).scroll(function() {
            $('#flat' + n).css('background-color', '');
            $(this).unbind();
        });
    }, 1100);
    
})